import './assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom"
import SignupHome from './pages/auth/SignupHome';
import Signup from './pages/auth/Signup';
import SendOtp from './pages/auth/SendOtp';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignupSchool from './pages/auth/SignupSchool';
import ProtectedRoute from './pages/auth/ProtectedRoute';
import SignupPublicSchool from './pages/auth/SignupPublicSchool';
import SignupPriviateSchool from './pages/auth/SignupPriviateSchool';
import ProfileSelect from './pages/auth/ProfileSelect';
import AddSchool from './components/normal/AddSchool';
import ThankYou from './pages/Thankyou';

import {Auth, API } from 'aws-amplify'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import ResendOtp from './pages/auth/ResendOtp';
import ProfileSelectSuccess from './pages/auth/ProfileSelectSuccess';
import Privacy from './pages/Privacy';
import PlayCity from './components/normal/PlayCity';
import AuthHome from './pages/auth/AuthHome';
import AboutUs from './pages/AboutUs';
import Stream from './pages/Stream';
import Challenges from './pages/Challenges';
import ScrapyShop from './pages/ScrapyShop';
import LeaderBoard from './pages/LeaderBoard';
import LeaderBoardMumbai from './pages/LeaderBoardMumbai';
import LeaderBoardBangalore from './pages/LeaderBoardBangalore';
import Choice from './pages/Choice';
Amplify.configure(awsExports);
Auth.configure(awsExports)

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/home" element={ <SignupHome />  } /> */}
        <Route path="/home" element={ <AuthHome /> } />
        <Route path="/" element={ 
        // <ProtectedRoute> 
          <Home /> 
        // </ProtectedRoute> 
      } 
        />
        <Route path="/choice" element={ <Choice /> } />
        <Route path="/signup" element={ <Signup /> } />
        <Route path="/signin" element={ <Login /> } />
        <Route path="/forgot-password" element={ <ForgotPassword /> } />
        <Route path="/reset-password" element={ <ResetPassword /> } />
        <Route path="/resend-otp" element={ <ResendOtp /> } />
        <Route path="/otp" element={ <SendOtp /> } />
        <Route path="/profile-success" element={ <ProfileSelectSuccess /> } />
        <Route path="/signup-school" element={ <SignupSchool /> } />
        <Route path="/signup-public-school" element={ <SignupPublicSchool /> } />
        <Route path="/signup-priviate-school" element={ <SignupPriviateSchool /> } />
        <Route path="/profile" element={ <ProfileSelect /> } />
        <Route path="/add-school" element={ <AddSchool /> } />
        <Route path="/thank-you" element={ <ThankYou /> } />
        <Route path="/privacy-policy" element={ <Privacy /> } />
        <Route path="/play-city" element={ <PlayCity /> } />
        <Route path="/about-us" element={ <AboutUs /> } />
        <Route path="/stream" element={ <Stream /> } />
        <Route path="/challenges" element={ <Challenges /> } />
        <Route path="/scrappy-shop" element={ <ScrapyShop /> } />
        <Route path="/leaderboard" element={ <LeaderBoard /> } />
        <Route path="/leaderboard-mumbai" element={ <LeaderBoardMumbai /> } />
        <Route path="/leaderboard-bangalore" element={ <LeaderBoardBangalore /> } />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;