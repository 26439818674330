import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import "yup-phone";
import { API, Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { getUser } from '../../graphql/queries';
import { addUserToLocalStorage } from '../../utils/localStorage';
import { useDispatch } from 'react-redux';
import { storeUser } from '../../features/user/userSlice';
import Navbar from '../../components/global/Navbar';
import Loading from '../../components/Loading';

const Login = () => {


  navigator.mediaDevices
  .getUserMedia({ audio: true, video: false })
  .then(function (stream) {
    playSound('https://scrappy-afe2cd64181944-prod.s3.ap-south-1.amazonaws.com/public/scrappy-news/waves-vfx.wav')

  })
  .catch(function (err) {
    console.log("No mic for you!");
  });
  
  function playSound(url) {
    const audio = new Audio(url);
    audio.play();
  }



    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

  
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const initialData = { phone: '', password: ''};
  
    const schema = Yup.object().shape({
        phone: Yup.string().required('Phone is required.')
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(6, "too short")
        .max(20, "too long"),
        password: Yup.string().required('Password is required.'),
    });


    const handleSubmit = async (values, {resetForm}) => {
      try {
        setLoading(true);
        const { phone, password } = values;
        const res = await Auth.signIn(`+91${phone}`, password);
        const getUserResponse = await API.graphql({ query: getUser, variables: { id: res?.username } });
        const user = getUserResponse?.data?.getUser;
        if (user) {
          dispatch(storeUser(user));
          addUserToLocalStorage(user);
          navigate('/signup-school');
          toast.success('Login Successfully');
        } else {
          toast.error('You might not be registered yet!');
        }
        resetForm({ values: '' });
      } catch (error) {
        console.error(error);
        toast.error(error?.message || 'An error occurred while logging in.');
      } finally {
        setLoading(false);
      }
    };
    

  
  //   const handleSubmit = async (values, {resetForm}) =>{
  //     try {
  //       setLoading(true)
  //       const { phone, password } = values;
  //       Auth.signIn( `+91${phone}`, password).then((res) => {
  //           API.graphql({ query: getUser, variables: { id: res?.username } }).then((res) => {
  //             const user = res?.data?.getUser;
  //             dispatch(storeUser(user));
  //             addUserToLocalStorage(user)
  //             if(user){
  //               navigate('/signup-school');
  //               toast.success('Login Successfully');
  //             } else{
  //               toast.error('You Might be not Register Yet!!!');
  //             }
  //             // if(user){
  //             // } else{
  //             //   toast.error('You Might be not Register Yet!!!');
  //             // }
  //           })
  //       })
  //       setLoading(false)
  //       // toast.error('check your password or number');
  //     resetForm({values: ''})
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error);
  //       toast.error(error.message);
  //     }
  // }

  if(loading){
    return <>
    <Loading />
    </>
  }

  return (
    <>
     <div className="signup-bg">
      <Navbar />
       <div className="bg-ground">
        <div className="ground-box">
        <div className="signup-home-img">
          <div className="home-relative">
        <img src="/img/page-3/signup-home.png" alt="Signup image" className="img-fluid home-small" />
        <div className="thread-box-one">
          <div className="position-relative">
        <img src="/img/page-3/thread-1.png" alt="" className="img-fluid w-100" />
        <div className="robo-moving">
        <img src="/img/page-3/Robo.gif" alt="" className="img-fluid w-100" />
        <div className="signup-pink-football">
          <img src="/img/page-3/Pink-Football.gif" alt="" className="img-fluid w-100" />
          </div>
        </div>
        
        <div className="signup-dress-code position-absolute">
        <img src="/img/page-3/dree-code.png" alt="" className="img-fluid w-100" />
        </div>

        <div className="leaf-green position-absolute">
        <img src="/img/page-3/leaf-green.png" alt="" className="img-fluid w-100" />
        </div>
    
        <div className="signup-right-tree">
          <img src="/img/page-3/Tree.gif" alt="" className="img-fluid w-100" />
        </div>

        <div className="shirt-anime-three">
          <img src="/img/page-3/Blue-Towel.gif" alt="" className="img-fluid" />
        </div>
        <div className="shirt-anime-two">
          <img src="/img/page-3/White-Towel.gif" alt="" className="img-fluid" />
        </div>
        <div className="shirt-anime">
          <img src="/img/page-3/Pink-T-Shirt.gif" alt="" className="img-fluid" />
        </div>
        <div className="shirt-anime-one">
          <img src="/img/page-3/White-T-Shirt.gif" alt="" className="img-fluid" />
        </div>


       <div className="form-area form-area-login">
         <div className="form-area-container">
          <img src="/img/page-3/login-form-table.png" alt="" className="img-fluid" />
           
          <Formik initialValues={initialData} validationSchema={schema} onSubmit={handleSubmit}>
            {({ values, errors, handleChange, handleSubmit }) => (
          <form className='form-paper pt-3 w-75' onSubmit={handleSubmit}>
            <div className="text-center">
          <img src="/img/page-3/Log-in.png" alt="" className="img-fluid mt-5" />
            </div>
          <div className='name-field mt-3 w-75'>
          <label htmlFor="phone" className="form-label mb-0">Mobile Phone Number <span className="text-danger">*</span></label>
          <input 
          type="tel"
          placeholder='Your Phone number' 
          className="form-control w-100 custom-form shadow-none pb-0 pt-0" 
          id="phone"
          name="phone" 
          aria-describedby="phoneHelp" 
          value={values.phone} onChange={handleChange}
          />
          {errors.phone && <small className='text-danger mt-2 ms-1 '>{errors.phone}</small>}
           </div>
          <div className='name-field mt-3 w-75'>
          <label htmlFor="password" className="form-label mb-0">Password <span className="text-danger">*</span></label>
          <input 
          type="name" 
          placeholder='Your Password' 
          className="form-control w-100 custom-form shadow-none pb-0 pt-0" 
          id="password" 
          name="password"
          aria-describedby="passwordHelp" 
          value={values.password} onChange={handleChange}
          />
          {errors.password && <small className='text-danger mt-2 ms-1 '>{errors.password}</small>}
           </div>
         
           <div className="submit-btn submit-btn-login">
            <button className="btn" type='submit'>
            <span className="submit-btn-text" >
            <img src="/img/page-3/submit-form.png" alt="" className="img-fluid" />
            <p>submit</p>
            </span>
            </button>
            </div>
            <div className="forgot-password">
            <Link to="/forgot-password" className='text-decoration-none text-dark mt-4'>Forgot Password</Link>
            </div>
          </form>
            )}
            </Formik>

            
                       <div className="welcome-scrappy">
                         <div className="position-relative">
                          <div className="notebook-clibs position-absolute">
                          <img src="/img/page-3/notebook-clibs.png" alt="" className="img-fluid" />
                          </div>
                         <img src="/img/page-3/welcome-scrappy.png" alt="" className="img-fluid" />
                         <div className="position-absolute form-coco-trees">
                         <img src="/img/page-3/coco-two-trees.png" alt="" className="img-fluid" />
                         </div>
                         </div>
                        </div>

                        <div className="field-notes">
                        <div className="position-relative">
                        <img src="/img/page-3/field-notes.png" alt="" className="img-fluid" />
                        <div className="position-absolute robo-2-img">
                          <div className="position-relative">
                        <img src="/img/page-3/robo-2.png" alt="" className="img-fluid" />
                        <div className="football-round">
                        <img src="/img/page-3/Football.gif" alt="" className="img-fluid" />
                      </div>
                          </div>
                        </div>
                        </div>
                        </div>

                        <div className="flower-left-signup-robo">
                        <img src="/img/signup-school/robo-4.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-baseball">
                        <img src="/img/signup-school/baseball.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-football">
                        <img src="/img/signup-school/football.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-tennisball">
                        <img src="/img/signup-school/tennis-ball.png" alt="" className="img-fluid" />
                      </div>


         </div>
       </div>
        </div>
         </div>
        <div className="thread-box-two">
        <img src="/img/page-3/thread-2.png" alt="" className="img-fluid w-100" />
         </div>
          </div>
        </div>
        </div>
       </div>



        

       <div className="waves-ocean">
          <img src="/img/page-3/Waves-ocean.gif" alt="" className="img-fluid" />
        </div>
       

        <div className="flower-left-signup-television">
          <img src="/img/page-3/signup-television.png" alt="" className="img-fluid" />
        </div>

        {/* <div className="flower-left-signup">
          <img src="/img/page-1/flower-left.png" alt="" className="img-fluid" />
        </div> */}

        {/* <div className="flower-right-signup">
          <img src="/img/page-1/flower-right.png" alt="" className="img-fluid" />
        </div> */}

        <div className="football-round">
          <img src="/img/page-3/Football.gif" alt="" className="img-fluid" />
        </div>

        {/* <div className="signup-coco-tree">
          <img src="/img/page-3/coconut-trees.png" alt="" className="img-fluid" />
        </div> */}

    </div>
    </>
  )
}

export default Login