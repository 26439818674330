import React from 'react'

const Loading = () => {
  return (
   <>
   <div className="loader-div">
  <span className="loader">
    <span />
    <span />
  </span>
  </div>
   </>
  )
}

export default Loading