import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';


const SendOtp = () => {

  const navigate = useNavigate()

  const { reguser } = useSelector((state) => state?.user)
  const initialData = { phone: '', otp: '' };


  useEffect(() => {
    setNewData()
  }, [])

  const setNewData = () => {
    initialData.phone = reguser?.phone
  }

  const otpSchema = Yup.object().shape({
    phone: Yup.string().required('Phone is required.')
  });

  const confirmOtp = async (phone, otp) => {
    let data;
    try {
      data = await Auth.confirmSignUp(`+91${phone}`, `${otp}`);
      if (data) {
        navigate('/signup-school')
        toast.success("OtP verification Success")
      }
    } catch (error) {
      console.log('form', data)
      console.log('error confirming sign up', error);
      toast.error(error.message)
    }
  }

  const handleSubmit = (values, { resetForm }) => {
    console.log(values, "values");
    let { phone, otp } = values;
    confirmOtp(phone, otp)
    resetForm({ values: '' })
  }

  // resend confirmation code 
  return (
    <>
      <div className="signup-bg">
        <div className="bg-ground">
          <div className="ground-box">
            <div className="signup-home-img">
              <div className="home-relative">
                <img src="/img/page-3/signup-home.png" alt="" className="img-fluid home-small" />
                <div className="thread-box-one">
                  <div className="position-relative">
                    <img src="/img/page-3/thread-1.png" alt="" className="img-fluid w-100" />
                    <div className="robo-moving">
                      <img src="/img/page-3/Robo.gif" alt="" className="img-fluid w-100" />
                    </div>

                    <div className="signup-dress-code position-absolute">
                    <img src="/img/page-3/dree-code.png" alt="" className="img-fluid w-100" />
                    </div>

                    <div className="leaf-green position-absolute">
                    <img src="/img/page-3/leaf-green.png" alt="" className="img-fluid w-100" />
                    </div>
                
                    <div className="signup-right-tree">
                      <img src="/img/page-3/Tree.gif" alt="" className="img-fluid w-100" />
                    </div>

                    <div className="shirt-anime-three">
                      <img src="/img/page-3/Blue-Towel.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime-two">
                      <img src="/img/page-3/White-Towel.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime">
                      <img src="/img/page-3/Pink-T-Shirt.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime-one">
                      <img src="/img/page-3/White-T-Shirt.gif" alt="" className="img-fluid" />
                    </div>



                    <div className="form-area">
                      <div className="form-area-container">
                        <img src="/img/page-3/form-area.png" alt="" className="img-fluid" />


                        <Formik initialValues={initialData} validationSchema={otpSchema} onSubmit={handleSubmit}>
                          {({ values, errors, handleChange, handleSubmit }) => (
                            <form className='form-paper pt-3 w-75' onSubmit={handleSubmit}>
                              <p className='text-center mt-5'> Enter OTP sent to {reguser?.phone} </p>


                              <div className="d-flex justify-content-center mb-2">
                                <p className='me-4'>Didn't receive an OTP?</p>
                                <Link type='btn' to="/resend-otp">Resend Otp</Link>
                              </div>

                              <div className='name-field mt-3'>
                                <label htmlFor="phone" className="form-label mb-0">Mobile Phone Number</label>
                                <input
                                  type="tel"
                                  placeholder='Your Phone number'
                                  className="form-control w-100 custom-form shadow-none pb-0 pt-0"
                                  id="phone"
                                  name="phone"
                                  aria-describedby="phoneHelp"
                                  value={values.phone} onChange={handleChange}
                                />
                                {errors.phone && <small className='text-danger mt-2 ms-1 '>{errors.phone}</small>}
                              </div>

                              <div className="otp-box d-flex justify-content-between">
                                <div className="otp-input-fields w-100">
                                  <img src="/img/page-3/otp.png" alt="" className="img-fluid otp-img w-100" style={{ height: '58px' }} />
                                  <input
                                    type="number"
                                    className="otp__digit"
                                    id="otp"
                                    name="otp"
                                    value={values.otp} onChange={handleChange}
                                    required
                                  />
                                </div>

                              </div>

                              <div className="submit-btn">
                                <button className="btn">
                                  <span className="submit-btn-text" type='submit'>
                                    <img src="/img/page-3/submit-form.png" alt="" className="img-fluid" />
                                    <p>submit</p>
                                  </span>
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>

                        <div className="welcome-scrappy">
                         <div className="position-relative">
                          <div className="notebook-clibs position-absolute">
                          <img src="/img/page-3/notebook-clibs.png" alt="" className="img-fluid" />
                          </div>
                         <img src="/img/page-3/welcome-scrappy.png" alt="" className="img-fluid" />
                         <div className="position-absolute form-coco-trees">
                         <img src="/img/page-3/coco-two-trees.png" alt="" className="img-fluid" />
                         </div>
                         </div>
                        </div>

                        <div className="field-notes">
                        <div className="position-relative">
                        <img src="/img/page-3/field-notes.png" alt="" className="img-fluid" />
                        <div className="position-absolute robo-2-img">
                          <div className="position-relative">
                        <img src="/img/page-3/robo-2.png" alt="" className="img-fluid" />
                        <div className="football-round">
                        <img src="/img/page-3/Football.gif" alt="" className="img-fluid" />
                      </div>
                          </div>
                        </div>
                        </div>
                        </div>

                        <div className="flower-left-signup-robo">
                        <img src="/img/signup-school/robo-4.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-baseball">
                        <img src="/img/signup-school/baseball.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-football">
                        <img src="/img/signup-school/football.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-tennisball">
                        <img src="/img/signup-school/tennis-ball.png" alt="" className="img-fluid" />
                      </div>



                      </div>

                    </div>
                  </div>
                </div>
                <div className="thread-box-two">
                  <img src="/img/page-3/thread-2.png" alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="waves-ocean">
          <img src="/img/page-3/Waves-ocean.gif" alt="" className="img-fluid" />
        </div>
       
       

        <div className="flower-left-signup-television">
          <img src="/img/page-3/signup-television.png" alt="" className="img-fluid" />
        </div>

        {/* <div className="flower-left-signup">
          <img src="/img/page-1/flower-left.png" alt="" className="img-fluid" />
        </div>

        <div className="flower-right-signup">
          <img src="/img/page-1/flower-right.png" alt="" className="img-fluid" />
        </div> */}

        <div className="football-round">
          <img src="/img/page-3/Football.gif" alt="" className="img-fluid" />
        </div>

        {/* <div className="signup-coco-tree">
          <img src="/img/page-3/coconut-trees.png" alt="" className="img-fluid" />
        </div> */}
      </div>

    </>
  )
}

export default SendOtp