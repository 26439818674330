import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import { toast } from 'react-toastify';
import { Auth, API } from 'aws-amplify'
import { createUser } from '../../graphql/mutations';
import { regUser } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import Navbar from '../../components/global/Navbar';

const Signup = () => {

  navigator.mediaDevices
  .getUserMedia({ audio: true, video: false })
  .then(function (stream) {
    playSound('https://scrappy-afe2cd64181944-prod.s3.ap-south-1.amazonaws.com/public/scrappy-news/waves-vfx.wav')

  })
  .catch(function (err) {
    console.log("No mic for you!");
  });
  
  function playSound(url) {
    const audio = new Audio(url);
    audio.play();
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const initialData = { firstName: '', lastName: '', phone: '', };

  const schema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    phone: Yup.string().required('Phone is required.')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(6, "too short")
      .max(20, "too long"),
  });


  // handleCreateUser  
  const handleCreateUser = async (firstName, lastName, phone) => {
    try {
      await Auth.signUp({
        username: `+91${phone}`,
        password: "Password@1",
        attributes: {
          family_name: firstName,
          given_name: lastName,
        },
      }).then((res) => {
        API.graphql({
          query: createUser,
          variables: {
            input: {
              deleted: false,
              id: res.userSub,
              firstName: firstName,
              lastName: lastName,
            },
          },
        })
      })
      navigate('/otp')
    } catch (error) {
      console.log('error signing in', error);
      toast.error(error.message)
    }
  }

  // handleSubmit 
  const handleSubmit = (values, { resetForm }) => {
    const { firstName, lastName, phone } = values
    handleCreateUser(firstName, lastName, phone)
    dispatch(regUser({ firstName, lastName, phone }));
    toast.success("Your Form Submitted Successfully")
    resetForm({ values: '' })
  }

  return (
    <>
   
      <div className="signup-bg">
          <Navbar />
        <div className="bg-ground">
          <div className="ground-box">
            <div className="signup-home-img">
              <div className="home-relative">
                <img src="/img/page-3/signup-home.png" alt="" className="img-fluid home-small" />
                <div className="thread-box-one">
                  <div className="position-relative">
                    <img src="/img/page-3/thread-1.png" alt="" className="img-fluid w-100" />
                    <div className="robo-moving">
                      <div className="position-relative">
                      <img src="/img/page-3/Robo.gif" alt="" className="img-fluid w-100" />
                      <div className="signup-pink-football">
                      <img src="/img/page-3/Pink-Football.gif" alt="" className="img-fluid w-100" />
                      </div>
                      
                      </div>
                    </div>


                    <div className="signup-dress-code position-absolute">
                    <img src="/img/page-3/dree-code.png" alt="" className="img-fluid w-100" />
                    </div>

                    <div className="leaf-green position-absolute">
                    <img src="/img/page-3/leaf-green.png" alt="" className="img-fluid w-100" />
                    </div>
                
                    <div className="signup-right-tree">
                      <img src="/img/page-3/Tree.gif" alt="" className="img-fluid w-100" />
                    </div>

                    <div className="shirt-anime-three">
                      <img src="/img/page-3/Blue-Towel.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime-two">
                      <img src="/img/page-3/White-Towel.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime">
                      <img src="/img/page-3/Pink-T-Shirt.gif" alt="" className="img-fluid" />
                    </div>
                    <div className="shirt-anime-one">
                      <img src="/img/page-3/White-T-Shirt.gif" alt="" className="img-fluid" />
                    </div>

                  
                    
                    <div className="form-area">
                      <div className="form-area-container">
                        <img src="/img/page-3/form-area.png" alt="" className="img-fluid" />

                        <Formik initialValues={initialData} validationSchema={schema} onSubmit={handleSubmit}>
                          {({ values, errors, handleChange, handleSubmit }) => (
                            <form className='form-paper pt-3 w-75' onSubmit={handleSubmit}>
                              <p> <b>Play Scrappy now. Create your account.</b> </p>
                              <div className='name-field'>
                                <label htmlFor="firstName" className="form-label mb-0">First Name</label>
                                <input
                                  type="name"
                                  placeholder='Your First Name'
                                  className="form-control w-100 custom-form shadow-none pb-0 pt-0"
                                  id="firstName"
                                  name="firstName"
                                  aria-describedby="firstNameHelp"
                                  value={values?.firstName} onChange={handleChange}
                                />
                                {errors.firstName && <small className='text-danger mt-2 ms-1 '>{errors.firstName}</small>}
                              </div>
                              <div className='name-field mt-3'>
                                <label htmlFor="lastName" className="form-label mb-0">Last Name</label>
                                <input
                                  type="name"
                                  placeholder='Your Last Name'
                                  className="form-control w-100 custom-form shadow-none pb-0 pt-0"
                                  id="lastName"
                                  name="lastName"
                                  aria-describedby="lastNameHelp"
                                  value={values?.lastName} onChange={handleChange}
                                />
                                {errors.lastName && <small className='text-danger mt-2 ms-1 '>{errors.lastName}</small>}
                              </div>
                              <div className='name-field mt-3'>
                                <label htmlFor="phone" className="form-label mb-0">Mobile Phone Number</label>
                                <input
                                  type="tel"
                                  placeholder='Your Phone number'
                                  className="form-control w-100 custom-form shadow-none pb-0 pt-0"
                                  id="phone"
                                  name="phone"
                                  aria-describedby="phoneHelp"
                                  value={values?.phone} onChange={handleChange}
                                />
                                {errors.phone && <small className='text-danger mt-2 ms-1 '>{errors.phone}</small>}
                              </div>
                              <div className="d-flex">
                                <p className='mr-2'>Do you already have an account?</p>
                                <Link to="/signin">Let's get going</Link>
                              </div>
                              <div className="submit-btn">
                                <button className="btn">
                                  <span className="submit-btn-text" type='submit'>
                                    <img src="/img/page-3/submit-form.png" alt="" className="img-fluid" />
                                    <p className='ps-2'>Go</p>
                                  </span>
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>

                        <div className="welcome-scrappy">
                         <div className="position-relative">
                          <div className="notebook-clibs position-absolute">
                          <img src="/img/page-3/notebook-clibs.png" alt="" className="img-fluid" />
                          </div>
                         <img src="/img/page-3/welcome-scrappy.png" alt="" className="img-fluid" />
                         <div className="position-absolute form-coco-trees">
                         <img src="/img/page-3/coco-two-trees.png" alt="" className="img-fluid" />
                         </div>
                         </div>
                        </div>

                        <div className="field-notes">
                        <div className="position-relative">
                        {/* <img src="/img/page-3/field-notes.png" alt="" className="img-fluid" /> */}
                        <div className="position-absolute robo-2-img">
                          <div className="position-relative">
                        <img src="/img/page-3/robo-2.png" alt="" className="img-fluid" />
                        <div className="football-round">
                        <img src="/img/page-3/Football.gif" alt="" className="img-fluid" />
                      </div>
                          </div>
                        </div>
                        </div>
                        </div>

                        <div className="flower-left-signup-robo">
                        <img src="/img/signup-school/robo-4.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-baseball">
                        <img src="/img/signup-school/baseball.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-football">
                        <img src="/img/signup-school/football.png" alt="" className="img-fluid" />
                      </div>

                      <div className="flower-left-signup-tennisball">
                        <img src="/img/signup-school/tennis-ball.png" alt="" className="img-fluid" />
                      </div>



                      </div>
                    </div>
                  </div>
                </div>
                <div className="thread-box-two">
                  <img src="/img/page-3/thread-2.png" alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="waves-ocean">
          <img src="/img/page-3/Waves-ocean.gif" alt="" className="img-fluid" />
        </div>
       
       

        <div className="flower-left-signup-television">
          <img src="/img/page-3/signup-television.png" alt="" className="img-fluid" />
        </div>

        {/* <div className="flower-left-signup">
          <img src="/img/page-1/flower-left.png" alt="" className="img-fluid" />
        </div> */}

        {/* <div className="flower-right-signup">
          <img src="/img/page-1/flower-right.png" alt="" className="img-fluid" />
        </div> */}

        

        {/* <div className="signup-coco-tree">
          <img src="/img/page-3/coconut-trees.png" alt="" className="img-fluid" />
        </div> */}

      </div>
    </>
  )
}

export default Signup