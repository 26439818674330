import { createSlice } from '@reduxjs/toolkit'
import { getUserFromLocalStorage, getUserImageFromLocalStorage } from '../../utils/localStorage'

const initialState = {
  user: getUserFromLocalStorage(),
  reguser: {},
  avatar: getUserImageFromLocalStorage(),
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUser: (state, {payload}) => {
        state.user = payload
    },
    regUser: (state, {payload}) => {
      state.reguser = payload
  },
  selectAvatar: (state, {payload}) =>{
    state.avatar = payload
  }
  },
})

// Action creators are generated for each case reducer function
export const { storeUser, regUser, selectAvatar } = userSlice.actions

export default userSlice.reducer